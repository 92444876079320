import Vue from 'vue';
import axios from 'axios'
import VueAxios from 'vue-axios'
import moment from 'moment'
import { LMap, LTileLayer, LMarker, LGeoJson } from 'vue2-leaflet';
import 'leaflet/dist/leaflet.css';
import vuetify from './plugins/vuetify';
import 'vuetify/dist/vuetify.min.css';
import App from './App.vue';

Vue.use(vuetify);
Vue.use(VueAxios, axios);

axios.defaults.baseURL = process.env.VUE_APP_API_URL;

Vue.prototype.$axios = axios;
Vue.prototype.$moment = moment;

Vue.component('l-map', LMap);
Vue.component('l-tile-layer', LTileLayer);
Vue.component('l-marker', LMarker);
Vue.component('l-geo-json', LGeoJson);

Vue.config.productionTip = false

new Vue({
  vuetify,
  axios,
  render: h => h(App),
}).$mount('#app')
