<template>
  <div>
    <loading :active.sync="isLoading" :can-cancel="true" :on-cancel="onCancel" :is-full-page="fullPage" />
    <div class="header">
      <div>
        <img class="logo-100" :src="require('../assets/mandaue-logo.png')" />
        <img class="logo-100" :src="require('../assets/cpdo-logo.png')" />
      </div>
      Project Monitoring System
    </div>
    <div v-if="hideMap" id="map-container">
      <div v-if="allProjects.length > 0" class="project-select-buttons">
        <v-btn color="primary" @click="showMobileButtons()" class="mb-3">{{ showProjectsListLabel }}</v-btn>
      </div>
      <div v-if="allProjects.length > 0" class="project-select">
        <h4>Project List w/ Locations</h4>
        <div class="search-field">
          <div>
            <v-text-field label="Enter Year or Project Title" v-model="searchFilterVar" @keyup="searchFilter($event)"></v-text-field>
          </div>
          <div>
            <v-select
              v-model="brgySelect"
              :items="brgyList"
              label="Select Barangay"
              @change="searchBrgyFilter"
              single-line
            ></v-select>
          </div>
          <div>
            <v-select
              v-model="statusSelect"
              :items="statusList"
              label="Select Status"
              @change="searchStatusFilter"
              single-line
            ></v-select>
          </div>
        </div>
        <div v-for="project in projects" :key="project.id" @click="showPolygon(project)" class="project-map">
          <strong>{{ project.year }}</strong> - {{ project.title }}
        </div>
        <div v-if="projects.length === 0" class="empty-search">
          No Projects found.
        </div>
      </div>
      <div v-if="allProjects.length > 0" class="project-legend">
        <h4>Project Status Legend:</h4>
        <div class="color-container"><span class="color-box ongoing"></span>Ongoing</div>
        <div class="color-container"><span class="color-box completed"></span>Completed</div>
        <div class="color-container"><span class="color-box suspended"></span>Suspended</div>
      </div>
      <div v-if="allProjects.length > 0 && showProjectsMobile" class="project-select-mobile">
        <h4>Project List w/ Locations</h4>
        <div class="search-field">
          <div>
            <v-text-field label="Enter Year or Project Title" v-model="searchFilterVar" @keyup="searchFilter($event)"></v-text-field>
          </div>
          <div>
            <v-select
              v-model="brgySelect"
              :items="brgyList"
              label="Select Barangay"
              @change="searchBrgyFilter"
              single-line
            ></v-select>
          </div>
          <div>
            <v-select
              v-model="brgySelect"
              :items="statusList"
              label="Select Status"
              @change="searchStatusFilter"
              single-line
            ></v-select>
          </div>
        </div>
        <div v-for="project in projects" :key="project.id" @click="showPolygon(project)" class="project-map">
          <strong>{{ project.year }}</strong> - {{ project.title }}
        </div>
        <div v-if="projects.length === 0" class="empty-search">
          No Projects found.
        </div>
      </div>
      <div v-if="allProjects.length > 0 && showLegendsMobile" class="project-legend-mobile">
        <h4>Project Status Legend:</h4>
        <div class="color-container"><span class="color-box ongoing"></span>Ongoing</div>
        <div class="color-container"><span class="color-box completed"></span>Completed</div>
        <div class="color-container"><span class="color-box suspended"></span>Suspended</div>
      </div>
      <l-map ref="monitoringMap" :zoom="zoom" :center="center" @click="mapClick">
        <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
        <l-geo-json
          :geojson="boundaryMap"
          :options="borderOptions"
        ></l-geo-json>
        <l-geo-json
          v-for="pMap in projectMapList"
          v-bind:key="pMap.id"
          v-bind:value="pMap.id"
          :geojson="pMap"
          :options="geoOptions"
        ></l-geo-json>
      </l-map>
    </div>
    <!-- <div id="data-graphs" v-html="powerBIFrame"></div> -->
    <v-container>
      <v-row>
        <v-col class="mb-9" cols="12">
          <h2>Complaints/Feedback</h2>
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation
          >
            <v-text-field
              v-model="name"
              :rules="nameRules"
              label="Name"
              required
            ></v-text-field>

            <v-text-field
              v-model="email"
              :rules="emailRules"
              label="E-mail"
              required
            ></v-text-field>

            <v-text-field
              v-model="cellphone"
              label="Cellphone Number"
              :rules="cellRules"
              counter="11"
            ></v-text-field>

            <v-select
              v-model="selectedProjectForm"
              :items="projectsFormList"
              item-text="title"
              item-value="title"
              label="Select Project"
            ></v-select>

            <v-text-field
              v-model="vidImgUrl"
              label="Youtube Video or Image Link"
            ></v-text-field>

            <v-textarea
              name="input-7-1"
              label="Message"
              v-model="comment"
              value=""
              hint="Hint text"
            ></v-textarea>

            <v-btn
              :disabled="!valid"
              color="primary"
              class="mt-3 mr-4 mb-3"
              medium
              @click="submit"
              :loading="loading"
            >
              Submit
            </v-btn>

            <v-btn
              color="error"
              class="mt-3 mr-4 mb-3"
              @click="reset"
            >
              Reset Form
            </v-btn>

            <v-btn
              color="warning"
              class="mt-3 mb-3"
              @click="resetValidation"
            >
              Reset Validation
            </v-btn>
          </v-form>
          <v-snackbar
            v-model="snackbar"
            :multi-line="multiLine"
            color="blue-grey"
          >
            Complaint submitted!
            <template v-slot:action="{ attrs }">
              <v-btn
                color="red"
                text
                v-bind="attrs"
                @click="snackbar = false"
              >
                Close
              </v-btn>
            </template>
          </v-snackbar>
        </v-col>
      </v-row>
    </v-container>
    <div class="footer">
      <v-container class="cm-footer-address">
        <v-row>
          <v-col class="counter" cols="12" xs="6" sm="6" md="4">
            <a href="https://www.webfreecounter.com/" target="_blank"><img src="https://www.cutercounter.com/hits.php?id=hrxfpckp&nd=6&style=13" border="0" alt="website counter"></a>
          </v-col>
          <v-col cols="12" xs="6" sm="6" md="4">
          </v-col>
          <v-col class="develop" cols="12" xs="6" sm="6" md="4">
            <a href="http://emtaservices.com/" target="_blank"
              >Developed by: EMTA Trading and Services.</a
            >
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import brgyMap from '@/geojson/brgyBoundary.js';
import * as L from "leaflet";

export default {
  name: 'HomePage',
  components: {
    Loading
  },
  data() {
    return {
      url: "https://{s}.tile.osm.org/{z}/{x}/{y}.png",
      //url: "https://tiles.stadiamaps.com/tiles/alidade_smooth/{z}/{x}/{y}{r}.png",
      zoom: 14,
      center: [10.333333, 123.933334],
      attribution:
        '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
      // attribution:
      //   '&copy; <a href="https://stadiamaps.com/">Stadia Maps</a>, &copy; <a href="https://openmaptiles.org/">OpenMapTiles</a> &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors',
      map: null,
      valid: true,
      name: '',
      nameRules: [
        v => !!v || 'Name is required',
      ],
      email: '',
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      ],
      vidImgUrl: '',
      cellphone: '',
      cellRules: [
        v => !!v || 'Cellphone number is required',
        v => (v && v.length <= 10) || 'Enter a valid 11 digits cellphone number.',
      ],
      selectedProjectForm: '',
      arrayBounds: [],
      comment: '',
      checkbox: false,
      hideMap: true,
      loading: false,
      powerBIFrame: '',
      snackbar: false,
      multiLine: true,
      projectMapList: [],
      projects: [],
      allProjects: [],
      projectsFormList: [],
      selectedProject: null,
      searchFilterVar: '',
      showProjectsListLabel: "Show Projects",
      showProjectsLegendLabel: "Show Color Legends",
      showProjectsMobile: false,
      showLegendsMobile: false,
      brgyList: [
        { text: 'Select Barangay', value: '' },
        { text: 'ALANG - ALANG', value: 'ALANG-ALANG' },
        { text: 'BAKILID', value: 'BAKILID' },
        { text: 'BANILAD', value: 'BANILAD' },
        { text: 'BASAK', value: 'BASAK' },
        { text: 'CABANCALAN', value: 'CABANCALAN' },
        { text: 'CAMBARO', value: 'CAMBARO' },
        { text: 'CANDUMAN', value: 'CANDUMAN' },
        { text: 'CASILI', value: 'CASILI' },
        { text: 'CASUNTINGAN', value: 'CASUNTINGAN' },
        { text: 'CENTRO', value: 'CENTRO' },
        { text: 'CSSEAZ', value: 'CSSEAZ' },
        { text: 'CUBACUB', value: 'CUBACUB' },
        { text: 'GUIZO', value: 'GUIZO' },
        { text: 'IBABAO - ESTANCIA', value: 'IBABAO-ESTANCIA' },
        { text: 'JAGOBIAO', value: 'JAGOBIAO' },
        { text: 'LABOGON', value: 'LABOGON' },
        { text: 'LOOC', value: 'LOOC' },
        { text: 'MAGUIKAY', value: 'MAGUIKAY' },
        { text: 'MANTUYONG', value: 'MANTUYONG' },
        { text: 'OPAO', value: 'OPAO' },
        { text: 'PAGSABUNGAN', value: 'PAGSABUNGAN' },
        { text: 'PAKNAAN', value: 'PAKNAAN' },
        { text: 'SUBANGDAKU', value: 'SUBANGDAKU' },
        { text: 'TABOK', value: 'TABOK' },
        { text: 'TAWASON', value: 'TAWASON' },
        { text: 'TINGUB', value: 'TINGUB' },
        { text: 'TIPOLO', value: 'TIPOLO' },
        { text: 'UMAPAD', value: 'UMAPAD' },
      ],
      statusList: [
        { text: 'Select Status', value: '' },
        { text: 'Ongoing', value: 'Ongoing' },
        { text: 'Completed', value: 'Completed' },
        { text: 'Suspended', value: 'Implementation' },
      ],
      brgySelect: '',
      statusSelect: '',
      boundaryMap: null,
      isLoading: false,
      fullPage: true
    };
  },
  watch: {
    selectedProject: function(projObj) {
      if (projObj.layer.feature.geometry.type === 'Point') {
        this.map.flyTo([projObj.layer.getLatLng().lat, projObj.layer.getLatLng().lng], 19);
      } else {
        this.map.fitBounds(projObj.layer.getBounds()); 
      }
    }
  },
  computed: {
    borderOptions() {
      return {
        onEachFeature: (feature, layer) => {
          layer.setStyle({
            weight: 1,
            stroke: true,
            color: "#000000",
            opacity: .5,
            fillColor: "#808080",
            fillOpacity: .5
          });
        }
      }
    },
    geoOptions() {
      return {
        pointToLayer: function (feature, latlng) {
          let styleObject = null;
          if (feature.properties?.ProjectStatusSpecific && feature.properties?.ProjectStatusSpecific.toLowerCase() === 'ongoing') {
            styleObject = {
              weight: 10,
              stroke: true,
              color: "#FFFF00",
              opacity: 0.8,
              fillColor: "#FFFF00",
              fillOpacity: 0.5
            }
          } else if (feature.properties?.ProjectStatusSpecific && feature.properties?.ProjectStatusSpecific.toLowerCase() === 'completed') {
            styleObject = {
              weight: 10,
              stroke: true,
              color: "#008000",
              opacity: 0.8,
              fillColor: "#008000",
              fillOpacity: 0.5
            };
          } else if (feature.properties?.ProjectStatusSpecific && feature.properties?.ProjectStatusSpecific.toLowerCase() === 'disbursement') {
              styleObject = {
                weight: 10,
                stroke: true,
                color: "#FFA500",
                opacity: 0.8,
                fillColor: "#FFA500",
                fillOpacity: 0.5
              };
            } else {
              styleObject = {
                weight: 10,
                stroke: true,
                color: "#000000",
                opacity: 0.8,
                fillColor: "#000000",
                fillOpacity: 0.5
              };
            } 
          return L.circleMarker(latlng, styleObject);
        },
        onEachFeature: (feature, layer) => {
          if (feature.geometry.type === "Point") {
            this.arrayBounds.push(this.map.getBounds().contains(layer.getLatLng()));
            this.map.flyTo([layer.getLatLng().lat, layer.getLatLng().lng], 19);
          } else {
            this.arrayBounds.push(layer.getBounds());
            this.map.fitBounds(this.arrayBounds);
          }
          
          if (feature.geometry.type !== "Point") {
            if (feature.properties?.ProjectStatusSpecific && feature.properties?.ProjectStatusSpecific.toLowerCase() === 'ongoing') {
              layer.setStyle({
                weight: 10,
                stroke: true,
                color: "#FFFF00",
                opacity: 0.8,
                fillColor: "#FFFF00",
                fillOpacity: 0.5
              });
            } else if (feature.properties?.ProjectStatusSpecific && feature.properties?.ProjectStatusSpecific.toLowerCase() === 'completed') {
              layer.setStyle({
                weight: 10,
                stroke: true,
                color: "#008000",
                opacity: 0.8,
                fillColor: "#008000",
                fillOpacity: 0.5
              });
            } else if (feature.properties?.ProjectStatusSpecific && feature.properties?.ProjectStatusSpecific.toLowerCase() === 'suspended') {
              layer.setStyle({
                weight: 10,
                stroke: true,
                color: "#FFA500",
                opacity: 0.8,
                fillColor: "#FFA500",
                fillOpacity: 0.5
              });
            } else {
              layer.setStyle({
                weight: 10,
                stroke: true,
                color: "#000000",
                opacity: 0.8,
                fillColor: "#000000",
                fillOpacity: 0.5
              });
            }  
          }
          this.popUp(feature, layer);
        }
      };
    }
  },
  mounted() {
    this.boundaryMap = brgyMap;
    if(this.hideMap) {
      this.$refs.monitoringMap.mapObject._onResize();
      this.$nextTick(() => {
        this.map = this.$refs.monitoringMap.mapObject;
        this.map.scrollWheelZoom.disable();
      });
    }
  },
  created() {
    this.getProjects();
    // this.getReport();
  },
  methods: {
    showMobileButtons() {
      if (!this.showProjectsMobile) {
        this.showProjectsListLabel = "Hide Projects";
        this.showProjectsMobile = true;
        this.showLegendsMobile = true;
      } else {
        this.showProjectsListLabel = "Show Projects";
        this.showProjectsMobile = false;
        this.showLegendsMobile = false;
      }
    },
    searchFilter() {
      if(this.searchFilterVar) {
        this.brgySelect = '';
        this.statusSelect = '';
        this.projects = this.allProjects.filter((allProj) => allProj.searchTitle.toLowerCase().indexOf(this.searchFilterVar.toLowerCase()) > -1 );
      } else {
        this.projects = this.allProjects;
      }
    },
    searchBrgyFilter() {
      if(this.brgySelect) {
        this.projects = this.projects.filter((allProj) => allProj.barangay && allProj.barangay.toUpperCase() === this.brgySelect);
      } else {
        if(this.searchFilterVar) {
          this.projects = this.allProjects.filter((allProj) => allProj.searchTitle.toLowerCase().indexOf(this.searchFilterVar) > -1 );
        } else {
          this.projects = this.allProjects;
        }
      }
    },
    searchStatusFilter() {
      if(this.statusSelect) {
        this.projects = this.projects.filter((allProj) => allProj.status && allProj.status === this.statusSelect);
      } else {
        if(this.searchFilterVar) {
          this.projects = this.allProjects.filter((allProj) => allProj.searchTitle.toLowerCase().indexOf(this.searchFilterVar) > -1 );
        } else {
          this.projects = this.allProjects;
        }
      }
    },
    showPolygon(project) {
      if (project.layer.feature.geometry.type === 'Point') {
        this.map.flyTo([project.layer.getLatLng().lat, project.layer.getLatLng().lng], 19);
      } else {
        this.map.fitBounds(project.layer.getBounds()); 
      }
    },
    popUp(feature, layer) {
      let popupContent = "";

      this.projects.push({
        id: feature.properties.id,
        title: feature.properties.Title,
        searchTitle: `${feature.properties.FundingYear} - ${feature.properties.Title}`,
        year: feature.properties.FundingYear,
        barangay: feature.properties.Barangay,
        status: feature.properties.ProjectStatusSpecific,
        layer: layer
      });

      this.allProjects.push({
        id: feature.properties.id,
        title: feature.properties.Title,
        searchTitle: `${feature.properties.FundingYear} - ${feature.properties.Title}`,
        year: feature.properties.FundingYear,
        barangay: feature.properties.Barangay,
        status: feature.properties.ProjectStatusSpecific,
        layer: layer
      });

      popupContent += "<table class='popup-table'>";
      if(feature.properties.Title) {
        popupContent += "<tr>";
        popupContent += "<th>";
        popupContent += "Project Name: ";
        popupContent += "</th>";
        popupContent += "<td>";
        popupContent += feature.properties.Title;
        popupContent += "</td>";
        popupContent += "</tr>";
      }
      if(feature.properties.ProjectType) {
        popupContent += "<tr>";
        popupContent += "<th>";
        popupContent += "Project Type: ";
        popupContent += "</th>";
        popupContent += "<td>";
        popupContent += feature.properties.ProjectType;
        popupContent += "</td>";
        popupContent += "</tr>";
      }
      if(feature.properties.ImplementingAgency) {
        popupContent += "<tr>";
        popupContent += "<th>";
        popupContent += "Implementing Agency: ";
        popupContent += "</th>";
        popupContent += "<td>";
        popupContent += feature.properties.ImplementingAgency;
        popupContent += "</td>";
        popupContent += "</tr>";
      }
      if(feature.properties.ModeOfImplementation) {
        popupContent += "<tr>";
        popupContent += "<th>";
        popupContent += "Mode of Implementation: ";
        popupContent += "</th>";
        popupContent += "<td>";
        popupContent += feature.properties.ModeOfImplementation;
        popupContent += "</td>";
        popupContent += "</tr>";
      }
      if(feature.properties.ContractorCost) {
        const contractorCost = feature.properties.ContractorCost.replaceAll(",", "");
        popupContent += "<tr>";
        popupContent += "<th>";
        popupContent += "Contractor Cost: ";
        popupContent += "</th>";
        popupContent += "<td>";
        popupContent += '&#8369; ' + Number(contractorCost).toLocaleString('en');
        popupContent += "</td>";
        popupContent += "</tr>";
      }
      if(feature.properties.Contractor) {
        popupContent += "<tr>";
        popupContent += "<th>";
        popupContent += "Contractor: ";
        popupContent += "</th>";
        popupContent += "<td>";
        popupContent += feature.properties.Contractor;
        popupContent += "</td>";
        popupContent += "</tr>";
      }
      if(feature.properties.ProjectStatusSpecific) {
        popupContent += "<tr>";
        popupContent += "<th>";
        popupContent += "Project Status Specifics: ";
        popupContent += "</th>";
        popupContent += "<td>";
        popupContent += feature.properties.ProjectStatusSpecific;
        popupContent += "</td>";
        popupContent += "</tr>";
      }
      if(feature.properties.StatusDate) {
        popupContent += "<tr>";
        popupContent += "<th>";
        popupContent += "As of Date: ";
        popupContent += "</th>";
        popupContent += "<td>";
        popupContent += feature.properties.StatusDate;
        popupContent += "</td>";
        popupContent += "</tr>";
      }
      if(feature.properties.Completed) {
        popupContent += "<tr>";
        popupContent += "<th>";
        popupContent += "Percentage of Completion: ";
        popupContent += "</th>";
        popupContent += "<td>";
        popupContent += Number(feature.properties.Completed) + '%';
        popupContent += "</td>";
        popupContent += "</tr>";
      }
      if(feature.properties.PhotoLink) {
        popupContent += "<tr>";
        popupContent += "<th>";
        popupContent += "Photo: ";
        popupContent += "</th>";
        popupContent += "<td>";
        popupContent += `<a href="${feature.properties.PhotoLink}" target="_blank">Click here</a>`;
        popupContent += "</td>";
        popupContent += "</tr>";
      }
      if(feature.properties.VideoUrl) {
        popupContent += "<tr>";
        popupContent += "<th>";
        popupContent += "Video: ";
        popupContent += "</th>";
        popupContent += "<td>";
        popupContent += `<a href="${feature.properties.VideoUrl}" target="_blank">Click here</a>`;
        popupContent += "</td>";
        popupContent += "</tr>";
      }

      popupContent += "</table>";

      layer.bindPopup(popupContent, { maxHeight: 400 });
    },
    getProjects() {
      this.isLoading = true;
      this.$axios.get('read', {
          // headers: {
          //   "Accept": "application/json;odata=nometadata",
          //   "Content-Type": "application/json;odata=verbose",
          // }
          headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
          }
        })
      .then(
        res => {
          console.log('res: ', res.data);
          for(const proj of res.data) {
            if (
              (proj.Project_Location || (proj.Project_Location != null && proj.Project_Location.includes('FeatureCollection'))) &&
              proj.Project_Location && proj.Status === 'Ongoing' ||
              proj.Project_Location && proj.Status === 'Completed' ||
              proj.Project_Location && proj.Status === 'Suspended'
            ) {
              let location = proj.Project_Location.replace(/\s+/g, '');
              location = location.replace(/[\u200B-\u200D\uFEFF]/g, '');
              const tempMap = JSON.parse(location);
              tempMap.features[0].properties.Budget = proj?.Budget || '';
              tempMap.features[0].properties.Completed = proj?.Completed || '';
              tempMap.features[0].properties.ConstructionConsultant = proj?.ConstructionConsultant || '';
              tempMap.features[0].properties.ContactNumber = proj?.ContactNumber || '';
              tempMap.features[0].properties.ContractID = proj?.ContractID || '';
              tempMap.features[0].properties.Contractor = proj?.Contractor || '';
              tempMap.features[0].properties.ContractorCost = proj?.ContractorCost || '';
              tempMap.features[0].properties.Department = proj?.DepartmentText || '';
              tempMap.features[0].properties.Description = proj?.Description || '';
              tempMap.features[0].properties.Duration = proj?.Duration || '';
              tempMap.features[0].properties.Title = proj?.TITLE || '';
              tempMap.features[0].properties.Status = proj?.Status || '';
              tempMap.features[0].properties.SourceOfFund = proj?.SourceOfFund || '';
              tempMap.features[0].properties.StartDate = proj?.Start_x0020_Date || '';
              tempMap.features[0].properties.EndDate = proj?.End_x0020_Date || '';
              tempMap.features[0].properties.FundingYear = proj?.Funding_x0020_Year || '';
              tempMap.features[0].properties.ImplementingAgency = proj?.Implementing_x0020_Agency || '';
              tempMap.features[0].properties.ModeOfImplementation = proj?.Mode_x0020_of_x0020_Implementation || '';
              tempMap.features[0].properties.ProjectType = proj?.Project_x0020_Type || '';
              tempMap.features[0].properties.StatusDate = proj?.ProjectStatusDate || '';
              tempMap.features[0].properties.ProjectStatusSpecific = proj?.Status || '';
              tempMap.features[0].properties.Barangay = proj?.Location || '';
              tempMap.features[0].properties.PhotoLink = proj?.Project_x0020_Photo || '';
              tempMap.features[0].properties.VideoUrl = proj?.Project_x0020_Video || '';
              this.projectMapList.push(tempMap);
            }

            this.projectsFormList.push({
              title: proj.TITLE,
            });
          }

          this.isLoading = false;
        }
      ).
      catch(
        error => {
          console.log(error)
          this.isLoading = false;
        }
      )
    },
    getReport() {
      this.$axios.get('PowerBIurl?ListTitle=Analytics Settings', {
          headers: {
            "Accept": "application/json;odata=nometadata",
            "Content-Type": "application/json;odata=verbose",
          }
        })
      .then(
        res => {
          let powerBI = res.data.replace('URL:', '');
          this.powerBIFrame = powerBI;
        }
      ).
      catch(
        error => {
          console.log(error)
        }
      )
    },
    mapClick() {
      if (this.map.scrollWheelZoom.enabled()) {
        this.map.scrollWheelZoom.disable();
      }
      else {
        this.map.scrollWheelZoom.enable();
      }
    },
    validate () {
      this.$refs.form.validate()
    },
    reset () {
      this.$refs.form.reset()
    },
    resetValidation () {
      this.$refs.form.resetValidation()
    },
    submit() {
      this.loading = true;
      this.$refs.form.validate();
      if (this.valid) {
        this.$axios.post('/AddContactUs', {
          name: this.name,
          emailAddress: this.email,
          phoneNumber: this.cellphone,
          link: this.vidImgUrl,
          comments: this.comment,
          project_name: this.selectedProjectForm
        }, {
          headers: {
            "Accept": "application/json;odata=nometadata",
            "Content-Type": "application/json;odata=verbose",
          }
        }).then(
          res => {
            if (res) {
              this.clearForm();
              this.$refs.form.reset();
              this.valid = true;
              this.snackbar = true;
            } else {
              console.log('submission failed');
            }
            this.loading = false;
          }
        ).catch(
          e => {
            console.log(e); 
            this.clearForm();
          }
        )
      }
    },
    clearForm() {
      this.name = '';
      this.email = '';
      this.cellphone = '';
      this.vidImgUrl = '';
      this.comment = '';
      this.selectedProjectForm = '';
    }
  }
}
</script>

<style>
.header {
  background-image: url("../assets/header-bg.png");
  background-repeat: no-repeat;
  color: #30137d;
  text-align: left;
  padding: 5px 20px 5px;
  font-size: 35px;
  display: flex;
  align-items: center;
  gap: 10px;
  text-transform: uppercase;
  justify-content: space-between;
  font-weight: bold;
  background-size: 100% 144px;
}

.header div {
  gap: 10px;
  display: flex;
}

.header img {
  width: 135px;
}

#map-container {
  height: 700px;
  margin-bottom: 25px;
  position: relative;
  border-top: solid 2px #30137d;
}

.project-select-buttons {
  display: none;
}

.project-select{
  position: absolute;
  top: 100px;
  z-index: 1000;
  background-color: #fff;
  opacity: 0.9;
  padding: 10px;
  max-height: 465px;
  overflow: scroll;
  width: 285px;
}

.project-select-buttons {
  position: absolute;
  top: 80px;
  z-index: 1000;
  background-color: #fff;
  opacity: 0.9;
  padding: 10px;
  max-height: 465px;
  overflow: scroll;
}

.project-select-mobile {
  position: absolute;
  top: 160px;
  z-index: 1000;
  background-color: #fff;
  opacity: 0.9;
  padding: 10px;
  max-height: 465px;
  overflow: scroll;
  width: 285px;
  display: none;
}

.project-legend-mobile {
  position: absolute;
  top: 15px;
  right: 15px;
  z-index: 1000;
  background-color: #fff;
  opacity: 0.9;
  padding: 10px;
  width: 115px;
  display: none;
}

.project-legend {
  position: absolute;
  top: 15px;
  right: 15px;
  z-index: 1000;
  background-color: #fff;
  opacity: 0.9;
  padding: 10px;
  width: 155px;
}

.popup-table {
  border-collapse: collapse;
}

.popup-table tr {
  border-bottom: solid 1px #000;
}

.popup-table td {
  padding: 15px 0px;
}

.leaflet-popup-content  {
  overflow-y: scroll;
  width: 301px;
  height: 400px;
}

#data-graphs {
  margin-top: 25px;
  margin-bottom: 25px;
}

#data-graphs iframe {
  width: 100%;
}

.v-text-field__details {
  display: none;
}

.v-list-item__title {
  text-align: left;
}

.footer {
  background-color: #15191f;
  color: #fff;
  margin-top: auto;
  padding: 20px 0px;
}

.develop a {
  color: #fff!important;
  text-decoration: none;
}

.project-map {
  border-bottom: solid 1px;
  padding: 15px;
  cursor: pointer;
  background-color: transparent;
  font-size: 15px;
  text-align: left;
}

.project-map:hover {
  background-color: #AEAEAE;
}

.project-legend .color-container,
.project-legend-mobile .color-container {
  display: flex;
  padding: 5px 0px;
  align-items: center;
  font-size: 11px;
}

.project-legend .color-box,
.project-legend-mobile .color-box  {
  width: 15px;
  height: 15px;
  display: block;
  border-radius: 9px;
  margin-right: 15px;
}

.project-legend h4,
.project-legend-mobile h4 {
  font-size: 14px;
}

.project-legend .completed,
.project-legend-mobile .completed {
  background-color: #008000;
}

.project-legend .ongoing,
.project-legend-mobile .ongoing {
  background-color: #FFFF00;
}

.project-legend .suspended,
.project-legend-mobile .suspended {
  background-color: #FFA500;
}

.empty-search {
  text-align: center;
  padding: 12px 0px;
  font-weight: bold;
}

@media only screen and (max-width: 1000px) {
  .header img {
    width: 75px;
  }

  .project-select,
  .project-legend {
    display: none;
  }

  .project-select-mobile,
  .project-legend-mobile {
    display: block;
  }

  .project-select-buttons {
    display: block;
  }

  .project-select-buttons button {
    width: 100%;
  }
}

@media only screen and (max-width: 800px) {
  .header {
    flex-direction: column;
    background-size: 100% 210px;
  }
  
  .header img {
    width: 127px;
  }
}

@media only screen and (max-width: 600px) {
  .header {
    font-size: 25px;
    text-align: center;
  }

  .header img {
    width: 105px;
  }
}
</style>
